import React from 'react';
import HomeScreen from './screens/Home';
import LoginScreen from './screens/Login';
import HomeAdminScreen from './screens/HomeAdmin';
import UsersListScreen from './screens/UsersList';
import UserDetailScreen from './screens/UserDetail';
import "./assets/css/App.css"
import { HashRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <HashRouter>
          <Routes>
            <Route exact path='/' element={<HomeScreen/>}></Route>
            <Route exact path='/login' element={<LoginScreen />} />
            <Route exact path='/home' element={<HomeScreen/>}></Route>
            <Route exact path='/home-admin' element={<HomeAdminScreen/>}></Route>
            <Route exact path="/users-list/:database" element={<UsersListScreen />}></Route>
            <Route exact path="/user-detail/:database/:username" element={<UserDetailScreen />}></Route>
          </Routes>
        </HashRouter>
    </>
  );
}
export default App;
