function Spinner() {

    return(
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
        );
}

export default Spinner;