import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import HomeImageDesktop from '../assets/img/image-Desktop-nomargin.png';
import '../assets/css/UsersListScreen.css';
import { readCookie } from "../utils/cookies"
import { Navigate } from 'react-router-dom';


function UserDetailScreen() {
    const { database, username } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const slugs = queryParams.get('slugs');

    const [collectionsData, setCollectionsData] = useState(null);
    const [error, setError] = useState(null);
    const [otherSlugs, setOtherSlugs] = useState([]);
    const [userSlugs, setUserSlugs] = useState([]);
    const navigate = useNavigate();

    const [selectedUrl, setSelectedUrl] = useState('');
    var isDesktop = window.innerWidth >= 751;

    const showImage = ()=>{
        //      desktop   y  nada seleccionado
        return (isDesktop && !selectedUrl)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`processor.php?module=admin&action=collections`, {
                    "crm": database,
                    "slugs": slugs,
                    "username": username
                },{
                    headers: {
                        'Authorization': `Bearer ${readCookie("token")}`
                      }
                });
                setCollectionsData(response.data);
                if (response.data.slugs) {
                    setOtherSlugs(response.data.slugs.other_slugs.filter(slug => slug !== null));
                    setUserSlugs(response.data.slugs.user_slugs);
                }
            } catch (error) {
                console.error('Error fetching collections:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [database, slugs, username]);

    const handleCheckboxChange = (event, slug) => {
        if (event.target.checked) {
            // Agregar a USER SLUGS y eliminar de OTHER SLUGS
            setUserSlugs(prevUserSlugs => [...prevUserSlugs, slug]);
            setOtherSlugs(prevOtherSlugs => prevOtherSlugs.filter(s => s !== slug));
        } else {
            // Eliminar de USER SLUGS y agregar a OTHER SLUGS
            setUserSlugs(prevUserSlugs => prevUserSlugs.filter(s => s !== slug));
            setOtherSlugs(prevOtherSlugs => [...prevOtherSlugs, slug]);
        }
    };

    const handleSaveSlugs = async () => {
        try {
            const formattedSlugs = userSlugs.join(',');
            const response = await axios.put(`processor.php?module=admin&action=update-slugs`, {
                "crm": database,
                "slugs": formattedSlugs,
                "username": username
            },{
                headers: {
                    'Authorization': `Bearer ${readCookie("token")}`
                  }
            });
            console.log('PUT Response:', response);
            // Mostrar mensaje de éxito
            alert('Se guardó satisfactoriamente');
            navigate(-1);
            

        } catch (error) {
            console.error('Error saving slugs:', error);
            setError(error.message);
        }
    };
    

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
        {
            readCookie("token")&&readCookie("company")&&readCookie("admin")=="true"?
            (
                <div className='users-screen-container'>
                    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <div>
                            <Header />
                        </div>
                        <div className='home-images-user' style={{display:"flex",flex:"100"}}>

                        {
                            showImage()&&
                            <img
                                src={HomeImageDesktop}
                                className={'img-desktop'}
                                id={"img-desktop-id"}
                                alt=''
                                loading='lazy'
                            />
                    }

                        <div className='users-cards-container'>
                            <h2>Usuario: {username}</h2>

                            <div>
                                <h4>OTHER SLUGS</h4>
                                {otherSlugs.map((slug, index) => (
                                    <div className="checkbox" key={index}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={userSlugs.includes(slug)}
                                                onChange={(event) => handleCheckboxChange(event, slug)}
                                            />
                                            {slug}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <h4>USER SLUGS</h4>
                                {userSlugs.map((slug, index) => (
                                    <div key={index}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={true}
                                                onChange={(event) => handleCheckboxChange(event, slug)}
                                            />
                                            {slug}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <button onClick={handleSaveSlugs}>Guardar</button>
                            
                        </div>

                        
                        </div>
                    </div>
                </div>
        ):(
            <Navigate to="/login" />
            )}
        </div>
    );
}

export default UserDetailScreen;
