import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import HomeImageDesktop from '../assets/img/image-Desktop-nomargin.png';
import UserCard from '../components/UserCard';
// import UserDetailScreen from './UserDetail';
import '../assets/css/UsersListScreen.css';
import { readCookie } from "../utils/cookies"
import { Navigate } from 'react-router-dom'


function UsersListScreen() {
  const [selectedUrl, setSelectedUrl] = useState('');
    var isDesktop = window.innerWidth >= 751;

    const showImage = ()=>{
        //      desktop   y  nada seleccionado
        return (isDesktop && !selectedUrl)
    }
  const { database } = useParams(); 
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`processor.php?module=admin&action=users`, {
          "crm": database
        },{
          headers: {
              'Authorization': `Bearer ${readCookie("token")}`
            }
      });
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [database]); // La dependencia [database] asegura que useEffect se ejecute cuando cambia el parámetro :database

  return (
    <div>
      {
        readCookie("token")&&readCookie("company")&&readCookie("admin")=="true"?
        (
          <div className='users-screen-container'>
              <div style={{minHeight:"100vh", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                  <div>
                      <Header/>
                  </div>
                  <div className='home-images' style={{flex:"100"}}>
                    {
                      showImage()&&
                      <img
                          src={HomeImageDesktop}
                          className={'img-desktop'}
                          id={"img-desktop-id"}
                          alt=''
                          loading='lazy'
                      />
                    }

                      <div className='users-cards-container'>
                          <h2>Lista de Usuarios</h2>
                      
                          {users.map(user => (
                              <Link
                              key={user.username}
                              to={`/user-detail/${database}/${user.username}?slugs=${user.slug ? encodeURIComponent(user.slug) : ''}`}
                            >
                                  <UserCard
                                  firstName={user.first_name || 'N/A'}
                                  lastName={user.last_name || 'N/A'}
                                  username={user.username || 'N/A'}
                                  slug={user.slug || 'N/A'}
                                  />
                              </Link>
                      ))}
                      </div>
                  </div>
              </div>
          </div>
        ):(
          <Navigate to="/login" />
        )}
    </div>
    

  );
}

export default UsersListScreen;
