export const readToken = (token) => {
  try {
      const payload = token.split('.')[1];
      const decodedPayload = atob(payload);
      return JSON.parse(decodedPayload);
  } catch (error) {
      console.error('Error decoding token:', error);
      return null;
  }
};


export const createCookies = (token,company,admin,refresh)=>{
    document.cookie = "admin=" + admin + ";" + expires + ";path=/;";

    const decoded_token = readToken(token);
    var date = new Date(decoded_token.exp * 1000);
    var expires = "expires=" + date.toUTCString();
    document.cookie = "token=" + token + ";" + expires + ";path=/;"

    if (refresh){
      var refresh_expires = new Date()
      refresh_expires.setDate(refresh_expires.getDate() + 7);
      document.cookie = "refresh=" + refresh + ";" + refresh_expires + ";path=/;";        
    }

    document.cookie = company?"company=" + company + ";" + expires + ";path=/;":"";
}


export const readCookie = (cname) => {

    let name = cname+"=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        const value = c.substring(name.length, c.length);
        if (name !== "token=" || name !== "refresh=") return value;

        const decoded_token = readToken(value);
        if (Date.now() > decoded_token.exp * 1000){
            return false
        }
        return value;
      }
    }
    return false;
}
