import React from "react";
import "../assets/css/formMessage.css"
export const FormMessage = ({ correctValidation }) => {
  return (
    <div>
      {
      correctValidation === 1 ? (
        <div
          className="bad-user"
          role="alert"
        >
          <h4>
          Usuario o contraseña incorrecto<br/>
          Por favor, intente nuevamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : 
      correctValidation === 2 ? (
        <div
          className="bad-user"
          role="alert"
        >
          <h4>
          Error en el campo compañia <br/>
          Por favor, intente nuevamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 3 ? (
        <div
          className="error-permission"
          role="alert"
        >
          <h4>
          No cuenta con los permisos suficientes
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : correctValidation === 4 ? (
        <div
          className="error-post"
          role="alert"
        >
          <h4>
          Ocurrio un error inesperado<br/>
          Por favor, intente nuevamente
          </h4>
          <br/>
          <i className="glyphicon glyphicon-thumbs-up"></i>
        </div>
      ) : null}
    </div>
  );
};
