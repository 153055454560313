import '../assets/css/HomeAdmin.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardAdmin from '../components/CardAdmin';
import { Navigate } from 'react-router-dom';
import { readCookie } from "../utils/cookies"

function HomeAdmin() {
    const [apiResponse, setApiResponse] = useState(null);
    const [selectedDatabase, setSelectedDatabase] = useState(null);
    const [databases, setDatabases] = useState({ databases: [] });
    
    useEffect(() => {
        axios.get('processor.php?module=admin&action=crms',{
            headers: {
                'Authorization': `Bearer ${readCookie("token")}`
              }
        }).then(response => {
                setDatabases({databases: response.data.databases});
            })
            .catch(error => console.error('Error al obtener las bases de datos:', error));
    }, []);

    const handleCardSelect = (database) => {
        axios.post(`processor.php?module=admin&action=users`, {
            "crm": database
        },{
            headers: {
                'Authorization': `Bearer ${readCookie("token")}`
              }
        }).then(response => {
            setApiResponse(response.data);
            setSelectedDatabase(database); // Actualiza el estado con la base de datos seleccionada
        })
        .catch(error => console.error('Error al realizar la solicitud:', error));
    };

    const renderCards = () => {
        return databases.databases.map((database, index) => (
            <CardAdmin
                key={index}
                title={database}
                text=" "
                onClick={() => handleCardSelect(database)}
            />
        ));
    };

    if (selectedDatabase){
        // Utiliza Navigate con el parámetro de la base de datos seleccionada para redirigir a la pantalla UsersList
        return <Navigate to={`/users-list/${selectedDatabase}`} replace />;
      }

    return (
        <div style={{flex:100}}>
            {
            readCookie("token")&&readCookie("company")&&readCookie("admin")=="true"?
            (
                <div className='home-admin-container'>
                    <h2>Bases de Datos</h2>
                    <div className='cards-container-admin'>
                        {renderCards()}
                    </div>
                    {apiResponse && <div apiResponse={apiResponse} />}
                </div>
            ):(
                    <Navigate to="/login" />
                )
            }
        </div>
    );
}

export default HomeAdmin;

