import React from 'react';
import PropTypes from 'prop-types';
import '../assets/css/UserCard.css'


function UserCard({ firstName, lastName, username, slug, onClick}) {
  return (
    <div className='user-card-container'>
        <div className="card-body-user" onClick={onClick}> 
            <h3 className="card-title-user">{firstName} {lastName}</h3>
            <p className='card-text-user'>Nombre de usuario: {username}</p>
            <p className='card-text-user'>Slug: {slug}</p>
        </div>
    </div>
  );
}

UserCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired, 
};
export default UserCard;


