import axios from "axios";
import { readCookie } from "./cookies"

export const loadCards = async (company)=>{
    return await axios({
        method: "POST",
        url: "processor.php?module=iframes",
        headers: {
        "Content-Type": "application/json", "Authorization": "Bearer " + readCookie("token")
        },
        data: company,
    })
    .then((res)=>res.data.dashboard)
    .catch(()=>null);

}
