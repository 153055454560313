import React from 'react';
import '../assets/css/Header.css'
import navbarImage from '../assets/img/navbar-logo.png';
//import hamburgerMenu from '../assets/img/HamburgerMenu2.svg';
import backArrow from '../assets/img/back_arrow.svg';
import logoutIcon from '../assets/img/logout.svg';
import { useLocation, Navigate } from 'react-router-dom';

var isDesktop = window.innerWidth >= 1001;

// mover a carpeta utils o algo asi
function logout(){
  window.sessionStorage.clear() //.setItem("token_user", data.token);
  window.location.reload()
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "company=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "admin=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

}


function RightButton(isSomethingSelected) {
  const location = useLocation();
  if (location.pathname === "/login") return

  if (!isDesktop&&!isSomethingSelected){
    return(
    <button className="header-right-container" onClick={logout}>
      <img src={logoutIcon} className='header-right-element'/>
    </button>
    );
  }
  else if(isDesktop){


  return (
    <button className="header-right-container" onClick={logout}>
      <span className='header-right-element'>
        Cerrar Sesión {/* traducir */}
      </span>
    </button>
    );
  }
}


function LeftButton(isSomethingSelected,onClick) {

  if (!isDesktop&&!isSomethingSelected){
    return(
      <span className="header-left-element" onClick={logout}>
        Master Dealer
      </span>
    );
  }
  else if (!isDesktop&&isSomethingSelected){
    return (
      <img
        src={!isDesktop&&isSomethingSelected?backArrow:navbarImage}
        className='navbar-image'
        alt=''
        loading='lazy'
        onClick={onClick}
      />
    );    
  }
  else if(isDesktop){

    return (
      <img
        src={!isDesktop&&isSomethingSelected?backArrow:navbarImage}
        className='navbar-image'
        alt=''
        loading='lazy'
        onClick={onClick}
      />
    );
  }


}


function Header({isSomethingSelected,onClick}) {
    return (
      <>
        <div className='navbar-container'>
              <div style={{display:'flex',width:"100%",flexDirection:"row",justifyContent:"space-between"}}>
                { LeftButton(isSomethingSelected,onClick) }
                { RightButton(isSomethingSelected,onClick) }
              </div>
        </div>
      </>
  );
}

export default Header;