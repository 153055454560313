import React, { useState } from 'react';
import "../assets/css/Login.css"
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn,
} from 'mdb-react-ui-kit';
import { Navigate } from 'react-router-dom'
import { FormMessage } from "./FormMessage";
import axios from "axios";
import Spinner from './Spinner';
import { createCookies,readCookie } from "../utils/cookies"


function Login() {
  const [formValue, setFormValue] = useState({
    company: '',
    username: '',
    password: '',
  });

  const [correctValidation, setCorrectValidation] = useState(0);
  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };
  const [isLoading, setIsLoading] = useState(false);

  const SignIn = async (e) =>{
    setIsLoading(true);
    e.preventDefault();
    await axios({
      method: "POST",
      url: "processor.php?module=login",
      headers: {
        "Content-Type": "application/json",
      },
      data: formValue,
    })
    .then((res) => {
      if (res.status === 200) {
        const data = res.data;
        if (data.token) {
            // Colocar el token del crm en sessionStorage
            createCookies(data.token,formValue.company,data.admin,data.refresh)
        } else{
          setCorrectValidation(3); // "No tiene los permisos de sesion (Vendedor)"
          setTimeout(() => {setCorrectValidation(0);}, 4000);
        }
    }
    setIsLoading(false);
   })
    .catch((err) => {
        if (err.request.status === 400){
          setCorrectValidation(2);
        }
        else if (err.request.status === 401){
          setCorrectValidation(1);
        }
        else{
          setCorrectValidation(4); // "Ha ocurrido un error inesperado"
        }
        setIsLoading(false);
        setTimeout(() => {setCorrectValidation(0);}, 4000);
  });
  
  setTimeout(() => {
      setCorrectValidation(0);
  }, 4000);
  }
  const inputStyles = {color:"white"}
  return (
    <div className='form-position'>
      <div className='form-body'>
        <div className='in-form-body'>
          <div className='welcome-text'>
            {/* Texto "Bienvenido a" en letra más pequeña y fina */}
            <h4 className='login-title'>Bienvenido a</h4>
          </div>
          <div>
            {/* Texto "ANALYTICS" en letra más grande y negrita */}
            <h2 className='login-subtitle'>ANALYTICS</h2>
            <p className='login-text'>¡Por favor ingresar compañía, usuario y contraseña!</p>
          </div>
          <div>
            <MDBValidation className='row g-3' onSubmit={SignIn}>
              <MDBValidationItem>
                <MDBInput className='form-input'
                  value={formValue.company}
                  name='company'
                  onChange={onChange}
                  id='item1'
                  required
                  label='Compañia'
                  labelStyle={inputStyles}
                  style={{color:"white"}} // Sobreescribe los estilos fijados x la libreria
                  />
              </MDBValidationItem>
              <MDBValidationItem>
                <MDBInput className='form-input'
                  value={formValue.username}
                  name='username'
                  onChange={onChange}
                  id='item2'
                  required
                  label='Usuario'
                  labelStyle={inputStyles}
                  style={{color:"white"}} // Sobreescribe los estilos fijados x la libreria
                  />
              </MDBValidationItem>
              <MDBValidationItem feedback='Por favor ingrese una contraseña correcta.' invalid>
                <MDBInput className='form-input'
                  value={formValue.password}
                  name='password'
                  onChange={onChange}
                  id='item3'
                  required
                  label='Contraseña'
                  type='password'
                  labelStyle={inputStyles}
                  style={{color:"white"}} // Sobreescribe los estilos fijados x la libreria
                  />
              </MDBValidationItem>
              <div className='login-button'>
                  <MDBBtn type='submit'>{
                    isLoading?
                    <Spinner className="loading-spinner" size={32} speed={1} animating={true} />:
                    "Inciar Sesión"
                  }</MDBBtn>
              </div>
            </MDBValidation>
          </div>
          {correctValidation !== 0 ? (
            <div className="message">
              <FormMessage correctValidation={correctValidation} />
            </div>
          ):(
            <span></span>
          )}
        </div>
      </div>
        <div>
          {
              readCookie("token")&&readCookie("company")&&readCookie("admin")?
              (
                <div>
                  {readCookie("admin") === "true" ?(<Navigate to='/home-admin'/>):(<Navigate to='/home'/>)}
                </div>
              ):
              (
                  <span></span>
              )
          }
      </div>
    </div>
  );


}
export default Login;
