import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from '../components/Login';
import React from 'react';

function LoginScreen() {
    return (

        <div className='login-screen-container'>
            <div style={{height:"100vh", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <Header />
                <Login />
                <Footer />
            </div>
        </div>

    );
}
    
export default LoginScreen;
    
