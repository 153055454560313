import React, { useState } from 'react';
import '../assets/css/HomeAdminScreen.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomeAdmin from '../components/HomeAdmin';
import HomeImageDesktop from '../assets/img/image-Desktop-nomargin.png';


function HomeAdminScreen() {
    const [selectedUrl, setSelectedUrl] = useState('');
    var isDesktop = window.innerWidth >= 751;

    const showImage = ()=>{
        //      desktop   y  nada seleccionado
        return (isDesktop && !selectedUrl)
    }
    return (
        
        <div className='home-screen-container-admin'>
            <div style={{minHeight:"100vh", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <div>
                    <Header/>
                </div>

                <div className='home-images' style={{display:"flex",flex:"100"}}>

                    
                    {
                        showImage()&&
                        <img
                            src={HomeImageDesktop}
                            className={'img-desktop'}
                            id={"img-desktop-id"}
                            alt=''
                            loading='lazy'
                        />
                    }
                    

                    <HomeAdmin />

                </div>
    
                <div>
                    <Footer />
                </div>
                
            </div>
        </div>
        

    );
}
    
export default HomeAdminScreen;
    
