import Card from './Card';
import '../assets/css/Home.css'
import { Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Spinner from './Spinner';
import { loadCards } from "../utils/external"
import { createCookies,readCookie } from "../utils/cookies"
import axios from "axios";


function Home({ onSelect,selectedURL }) {

    const [data, setData] = useState(
        null
    )
    const [titleSelected, setTitleSelected] = useState("");
    const [isIframeLoading, setIsIframeLoading] = useState(false);


    useEffect(() => {
        if (!!data) return
        const company = {"company" : readCookie("company")}
        const loadData = async () => {

            let refresh=readCookie("refresh")
            if (!readCookie("token")&&company["company"]&&refresh){
                await RefreshToken(company["company"],refresh);
            }
            let d = await loadCards(company); 
            setData(d);
        };
        loadData();
    },[data]);

    var isDesktop = window.innerWidth >= 1001;
    var iswideMobile = window.innerWidth <= 1001 && window.innerWidth >= 788;
    var isnarrowMobile = window.innerWidth <= 788;

    const mobileDashHeight = isnarrowMobile?"2777px":iswideMobile?"1777px":"100vh"
    //const mobileDashHeight = (1500000/(window.innerWidth*1.1)) + "px"

    const renderCollections = (categories) => {
        return Object.entries(categories).map(([categoryName, cards]) => (
            <div key={categoryName}>
                <h2 className='first-title'>{categoryName}</h2>
                <div className='cards-container'>
                    {renderDashboards(cards, categoryName)}
                </div>
                <br/>
            </div>
        ));
    }

    const renderDashboards = (cards, categoryName) => {
        return Object.entries(cards).map(([key, value]) => (
            <Card
                key={key}
                title={key}
                text={value["description"]}
                onSelect={() => {
                    onSelect(value.url);
                    setIsIframeLoading(value.url!==selectedURL);
                    setTitleSelected(categoryName);
                }}
            />
        ));
    };

    const showCards = ()=>{
        //       mobil     y   nada seleccionado  o   es desktop
        return (!isDesktop && !selectedURL)       ||  isDesktop
    }

    const RefreshToken = async (company, refresh_token) =>{

        await axios({
          method: "POST",
          url: "processor.php?module=refresh",
          headers: {
            "Content-Type": "application/json",
          },
          data: {refresh:refresh_token, company:company},
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            if (data.token) {
                // Colocar el token del crm en sessionStorage
                createCookies(data.token,company,data.admin, data.refresh)
            } else{
              return false;
            }
        }
        return false;
       })}

    if (!readCookie("token")&&readCookie("company")&&readCookie("refresh")){
        let company=readCookie("company")
        let refresh=readCookie("refresh")
        RefreshToken(company,refresh);
    }

    return (
        <div style={{flex:100}}>
            {
                (readCookie("token")||readCookie("refresh"))&&readCookie("company")?
                (
                    <div className='home-container'>
                        <div style={{
                            height: (!!selectedURL&&!isDesktop)?mobileDashHeight:"100%",
                            width:"100%",
                            padding:showCards()||isDesktop?"2vh":0,
                            display:"flex",
                            flexDirection:isDesktop?"row":"column",
                            }}>
                            <div>
                                {(showCards()||isDesktop)&&<h2 className='home-title'>{readCookie("company")}</h2>}
                                <div>
                                    {
                                        showCards()&&!!data&&
                                        renderCollections(data)
                                    }
                                    {!data&&<Spinner/>}
                                </div>
                            </div>

                            {selectedURL&&(
                                <div className="iframe-container">
                                    <h2 style={!isDesktop?{textAlign:"center",paddingTop:"1.5vh"}:null}className='iframe-title'>{titleSelected} {isIframeLoading&&<Spinner style={{height:"auto"}}/>}</h2>
                                    <iframe
                                        style={isDesktop?{backgroundColor:"white",width:"100%",height:"100%"}:null}
                                        src={selectedURL} title="dashboard-view" onLoad={()=>setIsIframeLoading(false)}
                                        />
                                </div>
                            )}
                        </div>
                    </div>
                ):(
                    <Navigate to="/login" />
                )
            }

        </div>

    );
}
    
export default Home;
    
