import React from 'react';
import '../assets/css/Footer.css'  

function Footer() {
  return (
    <div className="footer-container">
      <p className="text-white text-center">Master Dealer Analytics</p>
    </div>
  );
  
}

export default Footer;