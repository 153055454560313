import PropTypes from 'prop-types';
import '../assets/css/Card.css';

function Card({ title, text, onSelect }) {
    return (
        <div className="card-container">
            <div className="card-body" onClick={onSelect}>
                <h2 className="card-title">{title}</h2>
                <p className="card-text">{text}</p>
            </div>
        </div>
    );
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,

};

export default Card;