import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../components/Home';
import '../assets/css/HomeScreen.css'
import HomeImageDesktop from '../assets/img/image-Desktop-nomargin.png';

import React, { useState } from 'react';

function HomeScreen() {
    const [selectedUrl, setSelectedUrl] = useState('');
    var isDesktop = window.innerWidth >= 751;

    const showImage = ()=>{
        //      desktop   y  nada seleccionado
        return (isDesktop && !selectedUrl)
    }

    return (
        <div className='home-screen-container'>
            <div style={{minHeight:"100vh", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <div>
                    <Header isSomethingSelected={!!selectedUrl} onClick={()=>setSelectedUrl("")}/>
                </div>

                <div className='home-images' style={{display:"flex",flex:"100"}}>

                    {
                        showImage()&&
                        <img
                            src={HomeImageDesktop}
                            className={'img-desktop'}
                            id={"img-desktop-id"}
                            alt=''
                            loading='lazy'
                        />
                    }

                    <Home selectedURL={selectedUrl} onSelect={(a) => setSelectedUrl(a)}/>

                </div>
    
                <div>
                    <Footer />
                </div>
                
            </div>
        </div>
        

    );
}
    
export default HomeScreen;
    
