import React from 'react';
import PropTypes from 'prop-types';
import '../assets/css/CardAdmin.css';

function CardAdmin({ title, text, onClick }) { // Cambia onSelect a onClick en los parámetros
    return (
        <div className="card-container-admin">
            <div className="card-body-admin" onClick={onClick}> 
                <h2 className="card-title-admin">{title}</h2>
                <p className="card-text-admin">{text}</p>
            </div>
        </div>
    );
}

CardAdmin.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired, 
};

export default CardAdmin;
